import { useState } from "react";
import { useAlert } from "react-alert";
export default function ExcelForm(props) {
  const [details, setDetails] = useState({
    file_name: "",
    sp_name: "",
    sp_state: "",
    sp_emailId: "",
    sp_PhoneNumber: null,
    supplyCode: null,
    GSTIN: "",
    // invoiceNo: "",
    Date: "",
    // c_CompanyName: "",
    // c_Address: "",
    // c_GSTIN: null,
    // c_email: "",
    sp_panNumber: null,
    sp_AccocuntHolderName: "",
    sp_accountNumber: null,
    sp_IFSC_Code: "",
    sp_bankName: "",
    sp_branchName: "",
    Description: "",
    HSN_SAC: null,
    qty: null,
    unit_price: null,
  });
  const alert = useAlert();

  const postDetails = async (e) => {
    e.preventDefault();

    const headersList = {
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      file_name: details.file_name,
      sp_name: details.sp_name,
      sp_state: details.sp_state,
      sp_emailId: details.sp_emailId,
      sp_PhoneNumber: details.sp_PhoneNumber,
      supplyCode: details.supplyCode,
      GSTIN: details.GSTIN,
      // invoiceNo: details.invoiceNo,
      Date: details.Date,
      // c_CompanyName: details.c_CompanyName,
      // c_Address: details.c_Address,
      // c_GSTIN: details.c_GSTIN,
      // c_email: details.c_email,
      sp_panNumber: details.sp_panNumber,
      sp_AccocuntHolderName: details.sp_AccocuntHolderName,
      sp_accountNumber: details.sp_accountNumber,
      sp_IFSC_Code: details.sp_IFSC_Code,
      sp_bankName: details.sp_bankName,
      sp_branchName: details.sp_branchName,
      Description: details.Description,
      HSN_SAC: details.HSN_SAC,
      qty: details.qty,
      unit_price: details.unit_price,
    });
    props.setName(details.file_name);

    try {
      const response = await fetch(
        "https://node.sdpllabs.com/api/v1/PDF/uploadSingle",
        {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        }
      );

      const data = await response.json();
      console.log(data);
      if (data && data.success) {
        alert.success(data.message);
        // setDetails({
        //   file_name: "",
        //   sp_name: "",
        //   sp_state: "",
        //   sp_emailId: "",
        //   sp_PhoneNumber: null,
        //   supplyCode: null,
        //   GSTIN: "",
        //   invoiceNo: "",
        //   Date: "",
        //   // c_CompanyName: "",
        //   // c_Address: "",
        //   // c_GSTIN: null,
        //   // c_email: "",
        //   sp_panNumber: null,
        //   sp_AccocuntHolderName: "",
        //   sp_accountNumber: null,
        //   sp_IFSC_Code: "",
        //   sp_bankName: "",
        //   sp_branchName: "",
        //   Description: "",
        //   HSN_SAC: null,
        //   qty: null,
        //   unit_price: null,
        // });
      }
      if (data && data.success === false) alert.error(data.message);
      if (data && data.success && data.fileNumber) {
        sessionStorage.setItem("fileNumber", JSON.stringify(data));
        props.functionCall();
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  console.log(details);

  return (
    <>
      <div>
        <div className="maincontent">
          <div className="highlight all_form_spacing">
            <div className="row ">
              <form
                method="POST"
                name="contact-form"
                className="mt-3"
                onSubmit={postDetails}
              >
                <h3 style={{ color: "red" }}>Add Invoice Details </h3>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label htmlFor="fname">
                      Enter File Name <span className="msg_fail">*</span>
                    </label>

                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className="form-control"
                      onChange={(e) => {
                        setDetails({ ...details, file_name: e.target.value });
                      }}
                      placeholder="Enter the Name of the File"
                      value={details.file_name}
                      title="Enter only letters without space"
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="mname">Service Provider Name</label>
                    <input
                      type="text"
                      id="mname"
                      placeholder="Enter the Service Provider Name"
                      onChange={(e) => {
                        setDetails({ ...details, sp_name: e.target.value });
                      }}
                      name="mname"
                      title="Enter only letters without space"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="lname">Enter State Name</label>
                    <input
                      type="text"
                      id="lname"
                      placeholder="Enter State Name"
                      title="Enter only letters without space"
                      name="lname"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_state: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="email">Enter Email</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter the Email"
                      name="email"
                      className="form-control"
                      onChange={(e) => {
                        setDetails({ ...details, sp_emailId: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="oemail">Enter Phone Number</label>
                    <input
                      type="number"
                      id="oemail"
                      placeholder="Enter the Phone Number"
                      name="oemail"
                      className="form-control"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_PhoneNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="mobile">
                      Enter Supply Code <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      placeholder="Enter Supply Code"
                      name="mobile"
                      title="Enter a valid Supply Code"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setDetails({ ...details, supplyCode: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="">Enter GST Number</label>
                    <input
                      className=" form-control"
                      type="text"
                      placeholder="Enter the GST IN"
                      required
                      onChange={(e) => {
                        setDetails({ ...details, GSTIN: e.target.value });
                      }}
                    />
                  </div>
                  {/* <div className="col-md-4 mb-3">
                    <label htmlFor="aadhar">Invoice Number</label>
                    <input
                      type="text"
                      id="aadhar"
                      placeholder="Enter the Invoice Number"
                      title="Enter the invoice number"
                      name="invoice_number"
                      className="form-control"
                      onChange={(e) => {
                        setDetails({ ...details, invoiceNo: e.target.value });
                      }}
                      required
                    />
                  </div> */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Date</label>
                    <input
                      type="date"
                      id="pnumber"
                      placeholder="Enter the date"
                      required
                      title="Enter date"
                      onChange={(e) => {
                        setDetails({ ...details, Date: e.target.value });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  {/* <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Company Name</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the company name"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          c_CompanyName: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div> */}
                  {/* <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Company's Address</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the company's address"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({ ...details, c_Address: e.target.value });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>{" "} */}
                  {/* <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Company GSTIN</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the company gstin"
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({ ...details, c_GSTIN: e.target.value });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div> */}
                  {/* <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Company Email</label>
                    <input
                      type="email"
                      id="pnumber"
                      placeholder="Enter the company email"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({ ...details, c_email: e.target.value });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div> */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Company Pan Number</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the company pan number"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_panNumber: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Account Holder Name</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the account holder name"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_AccocuntHolderName: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Account Number</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the account number"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_accountNumber: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter IFSC Code</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the IFSC Code"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_IFSC_Code: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Bank Name</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the Bank Name"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_bankName: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Branch Name</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter the account number"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          sp_branchName: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Description</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Description"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          Description: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter HSN_SAC</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="HSN/SAC"
                      required
                      title="Enter HSN_SAC"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          HSN_SAC: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Quantity</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Quantity"
                      required
                      title="Enter company name"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          qty: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Enter Unit Price</label>
                    <input
                      type="text"
                      id="pnumber"
                      placeholder="Enter Unit Price"
                      required
                      title="Enter Unit Price"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          unit_price: e.target.value,
                        });
                      }}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mb-2">
                  Submit
                </button>
              </form>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
