import React from "react";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import loadingGif from "../src/assets/gif/Loading_icon.gif";
import moment from "moment/moment";
export default function DownloadedFile(props) {
  const [details, setDetails] = useState([]);
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const alert = useAlert();
  const showFileDetails = async () => {
    setIsLoading(true);
    const url = "https://node.sdpllabs.com/api/v1/PDF/getListData";
    const headersList = {
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      pageNumber: page,
    });
    try {
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      if (data.success) {
        setDetails(data.Data);
        setLength(data.length);
        setIsLoading(false);
      }
      if (data.success === false) alert.error("Internal Server Error");
    } catch (err) {
      alert.error("Internal Server Error");
      console.error(err);
      setIsLoading(true);
    }
  };
  useEffect(() => {
    showFileDetails();
  }, [page]);

  let TotalNumberOfPages = Math.ceil(length / 5) - 1;
  console.log(TotalNumberOfPages);
  console.log(details);

  return (
    <>
      {isLoading ? (
        // "Loading..."
      <div className="text-center">
          <img src={loadingGif} alt="" className="img-fluid" />
     
      </div>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">File Name</th>
              <th scope="col">Created At</th>
              <th scope="col">File Number</th>
              <th scope="col">Download</th>
            </tr>
          </thead>
          <tbody>
            {details.length === 0
              ? "*********No Data*********"
              : details.map((list) => {
                  return (
                    <tr>
                      {/* <td scope="col">{index}</td> */}
                      <td>{list && list.file_name}</td>
                      <td>{list && moment(list.createdAt).format("lll")}</td>
                      <td>{list && list.fileNumber}</td>
                      <td>
                        <button
                          className="btn btn-outline-success"
                          onClick={() => {
                            sessionStorage.setItem(
                              "fileNumber",
                              JSON.stringify(list)
                            );
                            props.functionCall();
                            props.close();
                          }}
                        >
                          Generate
                        </button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      )}

      <div className="d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li className="page-item">
              <button
                className={page === 0 ? "page-link disabled" : "page-link"}
                onClick={() => setPage((prev) => prev - 1)}
              >
                Previous
              </button>
            </li>

            <li className="page-item">
              <button
                className={
                  TotalNumberOfPages === page
                    ? "page-link disabled"
                    : "page-link"
                }
                onClick={() => setPage((prev) => prev + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
