import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
export default function NewFile(props) {
  const [fileDetails, setFileDetails] = useState({
    fileEnteredName: "",
    fileName: "",
    filePath: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const alert = useAlert();
  const postExcelDetails = async (e) => {
    setIsFetching(true)
    e.preventDefault();
    const headersList = {};
    const bodyContent = new FormData();
    bodyContent.append("fileName", fileDetails.fileEnteredName);
    bodyContent.append("image", fileDetails.fileName, fileDetails.filePath);

    try {
      const response = await fetch(
        "https://node.sdpllabs.com/api/v1/PDF/upload",
        {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        }
      );
      const data = await response.json();
      console.log(data);
      if (data.success === false) alert.error(data.message);
      else {
        sessionStorage.setItem("fileNumber", JSON.stringify(data));
        props.functionCall();
        props.setName(fileDetails.fileEnteredName);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  console.log(fileDetails);
  useEffect(() => {
    if (fileDetails) {
      setIsDisabled(false);
    }
  }, [fileDetails]);
  return (
    <>
      <div className="File_upload">
        <form onSubmit={postExcelDetails}>
          {/* <h6 style={{ color: "red" }} className="mt-3">
            Please Select An Excel File
          </h6> */}
          <div className="row">
            <div className="col-md-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) =>
                  setFileDetails({
                    ...fileDetails,
                    fileName: e.target.files[0],
                    filePath: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter File Name"
                onChange={(e) =>
                  setFileDetails({
                    ...fileDetails,
                    fileEnteredName: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="col-md-3">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isDisabled}
              >
                Upload
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
