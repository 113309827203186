import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Font,
} from "@react-pdf/renderer";

// Fonts Imports
import Roboto from "../src/assets/fonts/Roboto/Roboto-Regular.ttf";
// import RobotoBold from "../src/assets/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBlack from "../src/assets/fonts/Roboto/Roboto-Black.ttf";
import RobotoSemiBold from "../src/assets/fonts/Roboto/Roboto-Medium.ttf";
import FormattedToNameFormat from "./hooks/FormattedToNameFormat";
Font.register({
  family: "Roboto-Medium",
  src: RobotoSemiBold,
  fontWeight: "semibold",
});

Font.register({ family: "Roboto-Regular", src: Roboto, fontWeight: "normal" });

Font.register({ family: "Roboto-Black", src: RobotoBlack, fontWeight: "bold" });

// Fonts Import Ends

// Styles Sheet

const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 15,
    fontFamily: "Helvetica",
    // lineHeight: 26,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 10,
    fontSize: 14,
    textAlign: "justify",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  // Table CSS Starts

  // Prefer to not change the Table CSS (change it only if neccessary)
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderLeft: "none",
    borderRight: "none",
  },
  topRightTable: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    // borderWidth: 0.5,
    borderLeftWidth: 0.5,
    borderBottomWidth: 0.5,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
    //borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
  },
  tableRow: { flexDirection: "row", alignItems: "center" },
  tableCol: {
    // width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },

  // Table CSS Ends

  topBorderParent: {
    borderWidth: 1,
    borderStyle: "",
    // marginHorizontal: 5,
    // marginVertical: 0,
    fontFamily: "Roboto-Regular",
  },

  line: {
    height: 1,
    backgroundColor: "black",
    width: 290,
    paddingHorizontal: 4,
    marginVertical: 2,
  },
  fontBold: {
    //fontFamily: "Helvetica Bold",
    fontFamily: "Roboto-Black",
  },
  fontSemiBold: {
    // fontFamily: "EB Garamond SemiBold",
    fontFamily: "Roboto-Medium",
  },
  fontRegular: {
    fontFamily: "Roboto-Regular",
  },
  totalBox: {
    borderBottom: 1,
    borderColor: "#141414",
  },
  amountWords: {
    border: 1,
    borderColor: "#141414",
  },
  bankAccountDetails: {
    border: 1,
    borderColor: "#141414",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
  },
});

console.log("I am called");
const SampleDocument = ({ data }) => {
  console.log(data, "gfhg");
  console.log(data.Service_lists);

  const numWords = require("num-words");
  const subTotalToWords = Math.round(data.subTotal * 1.18);
  const amountInWords = numWords(subTotalToWords);
  console.log(subTotalToWords);
  console.log(amountInWords);
  const newSubtotalWords = Math.round(data.subTotal);
  const newSubtotalToWords = numWords(newSubtotalWords);
  // const capsAmount = amountInWords.capitalize();
  return (
    <Document style={{ width: "100%", height: 500 }}>
      <Page style={styles.body} orientation="portrait">
        <Text style={[{ textAlign: "center" }, styles.fontBold]}>
          Tax Invoice
        </Text>
        {/* Main View Starts */}
        <View style={styles.topBorderParent}>
          {/* TOp View With address info and invoice number  */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={{ width: "60%", marginLeft: 10, fontSize: 10 }}>
              <Text
                style={[styles.fontSemiBold, { marginBottom: 2, marginTop: 2 }]}
              >
                {" "}
                {data && data.sp_name ? data.sp_name : "-"}
              </Text>
              {/* 
              <Text
                style={[styles.fontRegular, { width: "70%", marginBottom: 2 }]}
              >
                {data && data.c_Address ? data.c_Address : "-"}
              </Text> */}
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "25%" }}>
                  <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                    GSTIN/UIN
                  </Text>
                  <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                    State Name
                  </Text>
                  <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                    PAN No.
                  </Text>
                  <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                    Email
                  </Text>
                </View>
                <View style={{ width: "5%" }}>
                  <Text style={{ marginBottom: 2 }}>:</Text>
                  <Text style={{ marginBottom: 2 }}>:</Text>
                  <Text style={{ marginBottom: 2 }}>:</Text>
                  <Text style={{ marginBottom: 2 }}>:</Text>
                </View>
                <View style={{ width: "70%" }}>
                  <Text style={[styles.fontRegular, { marginBottom: 2 }]}>
                    {data && data.GSTIN ? data.GSTIN : "-"}
                  </Text>
                  <Text style={[styles.fontRegular, { marginBottom: 2 }]}>
                    {data && data.sp_state ? data.sp_state : "-"}
                  </Text>
                  <Text style={[styles.fontRegular, { marginBottom: 2 }]}>
                    {data && data.sp_panNumber ? data.sp_panNumber : "-"}
                  </Text>
                  <Text style={[styles.fontRegular, { marginBottom: 2 }]}>
                    {data && data.sp_emailId ? data.sp_emailId : "-"}
                  </Text>
                </View>
              </View>

              {/* <View style={styles.fontRegular}>
                <Text
                  style={{
                    fontWeight: "100",
                    fontSize: 8,
                    marginBottom: 1,
                    marginTop: 1,
                  }}
                >
                  Consignee
                </Text>
                <Text
                  style={{ fontWeight: "100", fontSize: 12, marginBottom: 2 }}
                >
                  {data && data.c_CompanyName ? data.c_CompanyName : "-"}
                </Text>

                <Text
                  style={[
                    styles.fontRegular,
                    { width: "70%", marginBottom: 2 },
                  ]}
                >
                  {data && data.c_Address ? data.c_Address : "-"}
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "25%" }}>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      PAN/IT No.
                    </Text>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      State Name
                    </Text>
                  </View>
                  <View style={{ width: "5%" }}>
                    <Text style={{ marginBottom: 2 }}>:</Text>
                    <Text style={{ marginBottom: 2 }}>:</Text>
                  </View>
                  <View style={{ width: "70%" }}>
                    <Text
                      style={[
                        styles.fontRegular,
                        { width: "70%", marginBottom: 2 },
                      ]}
                    >
                      {data && data.sp_panNumber ? data.sp_panNumber : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.fontRegular,
                        { width: "70%", marginBottom: 2 },
                      ]}
                    >
                      {data && data.sp_state ? data.sp_state : "-"}
                    </Text>
                  </View>
                </View>
              </View> */}
              <View style={styles.line}></View>
              <View style={styles.fontRegular}>
                <Text
                  style={{
                    fontWeight: "100",
                    fontSize: 8,
                    marginBottom: 2,
                    marginTop: 1,
                  }}
                >
                  Buyer (if other than Consignee)
                </Text>
                <Text
                  style={{ fontWeight: "100", fontSize: 12, marginBottom: 2 }}
                >
                  {data && data.c_CompanyName ? data.c_CompanyName : "-"}
                </Text>
                <Text
                  style={[
                    styles.fontRegular,
                    { width: "70%", marginBottom: 2 },
                  ]}
                >
                  {data && data.c_Address ? data.c_Address : "-"}
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <View style={{ width: "25%" }}>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      GSTIN/UIN
                    </Text>

                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      PAN/IT No
                    </Text>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      State Name
                    </Text>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      Place Of Supply
                    </Text>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      Supply Code
                    </Text>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      Contact
                    </Text>
                    <Text style={[styles.fontSemiBold, { marginBottom: 2 }]}>
                      Email
                    </Text>
                  </View>
                  <View style={{ width: "5%" }}>
                    <Text>:</Text>
                    <Text>:</Text>
                    <Text>:</Text>
                    <Text>:</Text>
                    <Text>:</Text>
                    <Text>:</Text>
                    <Text>:</Text>
                    <Text>:</Text>
                  </View>
                  <View style={{ width: "70%" }}>
                    <Text
                      style={[
                        styles.fontRegular,
                        { fontSize: 10, marginBottom: 2 },
                      ]}
                    >
                      {data && data.c_GSTIN ? data.c_GSTIN : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.fontRegular,
                        { fontSize: 10, marginBottom: 2 },
                      ]}
                    >
                      {/* AADCF48302 */}
                      {data && data.sp_panNumber ? data.sp_panNumber : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.fontRegular,
                        { fontSize: 10, marginBottom: 2 },
                      ]}
                    >
                      {/* New Delhi */}
                      {data && data.sp_state ? data.sp_state : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.fontRegular,
                        { fontSize: 10, marginBottom: 2 },
                      ]}
                    >
                      {/* New Delhi */}
                      {data && data.sp_state ? data.sp_state : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.fontRegular,
                        { fontSize: 10, marginBottom: 2 },
                      ]}
                    >
                      {/* 07 */}
                      {data && data.supplyCode ? data.supplyCode : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.fontRegular,
                        { fontSize: 10, marginBottom: 2 },
                      ]}
                    >
                      {/* // 9311740924 */}
                      {data && data.sp_PhoneNumber ? data.sp_PhoneNumber : "-"}
                    </Text>
                    <Text
                      style={[
                        styles.fontRegular,
                        { fontSize: 10, marginBottom: 2 },
                      ]}
                    >
                      {data && data.c_email ? data.c_email : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "40%" }}>
              <View style={styles.topRightTable}>
                <View style={[styles.tableRow, {}]}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "50%", textAlign: "left" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 30, marginBottom: 2 },
                        styles.fontRegular,
                      ]}
                    >
                      Invoice No.
                      {"\n"}
                      {data && data.invoiceNo ? data.invoiceNo : "-"}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: "50%" }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 60, marginBottom: 2 },
                        styles.fontRegular,
                      ]}
                    >
                      Dated
                      {"\n"}
                      {data && data.Date ? data.Date : "-"}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow, {}]}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "50%", textAlign: "left" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 45 },
                        styles.fontRegular,
                      ]}
                    >
                      Delivery Note
                      {"\n"}{" "}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: "50%" }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 20 },
                        styles.fontRegular,
                      ]}
                    >
                      Mode of Payment
                      {"\n"}{" "}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow, {}]}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "50%", textAlign: "left" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 40 },
                        styles.fontRegular,
                      ]}
                    >
                      Supplier's Ref
                      {"\n"}{" "}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: "50%" }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 30 },
                        styles.fontRegular,
                      ]}
                    >
                      Other Reference
                      {"\n"}{" "}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow, {}]}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "50%", textAlign: "left" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 25 },
                        styles.fontRegular,
                      ]}
                    >
                      Buyer's Order No.
                      {"\n"}{" "}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: "50%" }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 60, marginBottom: 2 },
                        styles.fontRegular,
                      ]}
                    >
                      Dated
                      {"\n"}
                      {data && data.Date ? data.Date : "-"}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow, {}]}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "50%", textAlign: "left" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 30 },
                        styles.fontRegular,
                      ]}
                    >
                      Dispatch Doc No
                      {"\n"}{" "}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: "50%" }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 45 },
                        styles.fontRegular,
                      ]}
                    >
                      Delivery Date
                      {"\n"}{" "}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow, {}]}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "50%", textAlign: "left" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 25 },
                        styles.fontRegular,
                      ]}
                    >
                      Dispatch Through
                      {"\n"}{" "}
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: "50%" }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 50 },
                        styles.fontRegular,
                      ]}
                    >
                      Destination
                      {"\n"}{" "}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow, {}]}>
                  <View
                    style={[
                      styles.tableCol,
                      { width: "100%", textAlign: "left" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableCell,
                        { fontSize: 10, paddingRight: 130 },
                        styles.fontRegular,
                      ]}
                    >
                      Terms of Condition
                      {"\n"} {"\n"} {"\n"}{" "}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* TOp VIew Ends */}
          {/* Items Description Table */}
          <View style={[styles.table, { marginTop: 2 }]}>
            <View style={[styles.tableRow]}>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  S NO
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "30%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  DESCRIPTION
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  HSN/SAC
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "13%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  QTY
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "17%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  UNIT PRICE
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  AMOUNT
                </Text>
              </View>
            </View>
            {data &&
              data.Service_lists &&
              data.Service_lists.length >= 0 &&
              data.Service_lists.map((tableRow, index) => {
                console.log(tableRow);
                console.log(index);
                let newIndex = index + 1;
                return (
                  <>
                    <View
                      style={[
                        styles.tableRow,
                        { alignItems: "center", flexDirection: "row" },
                      ]}
                    >
                      <View style={[styles.tableCol, { width: "10%" }]}>
                        <Text style={[styles.tableCell, { fontSize: 10 }]}>
                          {newIndex}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: "30%" }]}>
                        <Text style={[styles.tableCell, { fontSize: 10 }]}>
                          {tableRow && tableRow.Description
                            ? tableRow.Description
                            : "-"}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: "15%" }]}>
                        <Text style={[styles.tableCell, { fontSize: 10 }]}>
                          {tableRow && tableRow.HSN_SAC
                            ? tableRow.HSN_SAC
                            : "-"}
                        </Text>
                      </View>
                      <View style={[styles.tableCol, { width: "13%" }]}>
                        <Text style={[styles.tableCell, { fontSize: 10 }]}>
                          {tableRow && tableRow.qty ? tableRow.qty : "-"}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.tableCol,
                          {
                            width: "17%",
                          },
                        ]}
                      >
                        <Text style={[styles.tableCell, { fontSize: 10 }]}>
                          {tableRow && tableRow.unit_price
                            ? tableRow.unit_price
                            : "-"}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.tableCol,
                          {
                            width: "15%",
                          },
                        ]}
                      >
                        <Text style={[styles.tableCell, { fontSize: 10 }]}>
                          {tableRow && tableRow.Ammount
                            ? tableRow.Ammount
                            : "-"}
                        </Text>
                      </View>
                    </View>
                  </>
                );
              })}
            <View style={[styles.tableRow]}>
              <View
                style={[
                  styles.tableCol,
                  { textAlign: "right", width: "85%", paddingRight: 160 },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  Total
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontRegular,
                  ]}
                >
                  {data && data.subTotal ? data.subTotal : "-"}
                </Text>
              </View>
            </View>
          </View>
          {/* Item Description Table ENds */}

          {/* <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 8 },
                    styles.fontRegular,
                  ]}
                >
                  Amount Chargable In Words {"\n"}
                  INR {newSubtotalToWords} Only/-
                </Text> */}
          {/* Amount In WOrds Section */}
          <View
            style={{
              marginHorizontal: 8,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            {/* <View style={[{ width: "20%" }]}></View> */}
            <View style={[{ width: "100%" }]}>
              <Text style={[styles.fontRegular, { fontSize: 10 }]}>
                Amount Chargable In Words {"\n"}
              </Text>
              <Text style={[styles.fontSemiBold, { fontSize: 10 }]}>
                INR {newSubtotalToWords} Only/-
              </Text>
            </View>
          </View>
          {/* Amount In Words Section ENds */}

          {/* Tax Table */}

          <View style={styles.table}>
            <View style={[styles.tableRow]}>
              <View style={[styles.tableCol, { width: "35%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  {" "}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  SGST (9%)
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  CGST (9%)
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { textAlign: "right", paddingRight: 3, width: "10%" },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  IGST (18%)
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  Total Taxable Amount
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  Total AMOUNT
                </Text>
              </View>
            </View>
            <View style={[styles.tableRow]}>
              <View style={[styles.tableCol, { width: "35%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  {" "}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  {data && data.isInterState === "NO"
                    ? data.subTotal * 0.09
                    : "-"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  {data && data.isInterState === "NO"
                    ? data.subTotal * 0.09
                    : "-"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  {data && data.isInterState === "YES"
                    ? data.subTotal * 0.18
                    : "-"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  {data && data.isInterState === "NoGST"
                    ? data.subTotal
                    : data.subTotal * 0.18}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text
                  style={[
                    styles.tableCell,
                    { fontSize: 10 },
                    styles.fontSemiBold,
                  ]}
                >
                  {data && data.isInterState === "NoGST"
                    ? data.subTotal
                    : Math.round(data.subTotal * 118) / 100}
                </Text>
              </View>
            </View>
          </View>
          {/* Tax Table ENds */}
          {/* Total Amount In WOrds */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginHorizontal: 8,
              marginTop: 5,
              marginBottom: 20,
            }}
          >
            <View style={[{ width: "40%" }]}>
              <Text style={[styles.fontRegular, { fontSize: 10 }]}>
                {" "}
                Total Amount Including Tax(in Words) :
              </Text>
            </View>
            <View style={[{ width: "60%" }]}>
              <Text style={[styles.fontSemiBold, { fontSize: 12 }]}>
                INR {amountInWords} Only/-
              </Text>
            </View>
          </View>
          {/* Total Amount in WOrds ENds */}
          <Text
            style={[styles.fontRegular, { marginHorizontal: 10, fontSize: 12 }]}
          >
            {data && data.sp_name ? data.sp_name : "-"}'s PAN {"     "}:
            <Text style={styles.fontSemiBold}>
              {" "}
              {data && data.sp_panNumber ? data.sp_panNumber : "-"}
            </Text>
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginHorizontal: 8,
              marginTop: 5,
            }}
          >
            <View style={{ width: "50%" }}>
              <Text style={[styles.fontRegular, { fontSize: 10 }]}>
                Declaration {"\n"}
                1. Any type of theft/burn/damage will be charged extra. {"\n"}2.
                No software provided along with the Hardware {"\n"}
              </Text>
              <Text style={[styles.fontRegular, { fontSize: 7 }]}>
                /information.{"  "} In case of any disputes, jurisdiction of
                court will be in Delhi Courts {"\n"} only
              </Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text style={[styles.fontRegular, { fontSize: 10 }]}>{"\n"}</Text>
              <Text style={[styles.fontSemiBold, { fontSize: 10 }]}>
                {data && data.sp_name ? data.sp_name : "-"}'s Bank Details
              </Text>
              <Text style={[styles.fontRegular, { fontSize: 10 }]}>
                Bank Name {"            "}:{" "}
                <Text style={styles.fontSemiBold}>
                  {data && data.sp_bankName ? data.sp_bankName : "-"}
                </Text>
              </Text>
              <Text style={[styles.fontRegular, { fontSize: 10 }]}>
                A/c No.{"                   "} :{" "}
                <Text style={styles.fontSemiBold}>
                  {data && data.sp_accountNumber ? data.sp_accountNumber : "-"}
                </Text>
              </Text>
              <Text style={[styles.fontRegular, { fontSize: 10 }]}>
                Branch & IFS Code {""}:{" "}
                <Text style={styles.fontSemiBold}>
                  {data && data.sp_IFSC_Code ? data.sp_IFSC_Code : "-"}
                </Text>
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.bankAccountDetails,
              {
                width: "100%",
                flexDirection: "row",
                // marginBottom: 2,
                marginLeft: 0,
                marginRight: 0,
              },
            ]}
          >
            <View
              style={{
                width: "50%",
                borderRight: 1,
                borderColor: "#141414",
                textAlign: "left",
              }}
            >
              <Text
                style={{ paddingLeft: 10, paddingBottom: 25, fontSize: 12 }}
              >
                Customer Signature
              </Text>
            </View>
            <View style={{ width: "50%", textAlign: "left" }}>
              <Text
                style={{ paddingLeft: 10, paddingBottom: 25, fontSize: 12 }}
              >
                Authorized Signature
              </Text>
            </View>
          </View>
        </View>
        {/* Main View Ends  */}
      </Page>
    </Document>
  );
};

export default SampleDocument;
