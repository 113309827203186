// import "./styles.css";
import { useState } from "react";
import SampleDocument from "./SampleDocument";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import NewFile from "./NewFile";
import ExcelForm from "./ExcelForm";
import DownloadedFile from "./DownloadedFile";
import { useAlert } from "react-alert";
import Modal from "react-bootstrap/Modal";
import loadingGif from "../src/assets/gif/Loading_icon.gif";
export default function App() {
  // const [details, setDetails] = useState({
  //   file_number: "",
  // });

  const [plants, setPlants] = useState([]);
  const [formShow, setFormShow] = useState({
    individual: false,
    excel: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [fileName, setFileName] = useState("");
  //modal states
  const [show, setShow] = useState(false);
  const alert = useAlert();
  const getTheFileData = async () => {
    setIsFetching(true);
    const fileData = JSON.parse(window.sessionStorage.getItem("fileNumber"));
    const URL =
      "https://node.sdpllabs.com/api/v1/PDF/getPdf/" + fileData.fileNumber;
    console.log(URL);
    try {
      const response = await fetch(URL, {
        method: "GET",
        headers: {},
      });
      const data = await response.json();
      console.log(data);
      const result = data.Data;
      console.log(result);
      // setPlants(data.Data);
      setPlants(
        result.map((totalData) => {
          return {
            ...totalData,
            isInterState:
              totalData.GSTIN && totalData.GSTIN.slice(0, 2) === "07"
                ? "NO"
                : totalData.GSTIN && totalData.GSTIN.slice(0, 2) !== "07"
                ? "YES"
                : "NoGST",
            subTotal: totalData.Service_lists.reduce((a, c) => {
              return a + c.Ammount;
            }, 0),
          };
        })
      );
      setIsFetching(false);

      if (data && data.success) {
        alert.success(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  // const plants=[
  //   {
  //       "id": 1,
  //       "file_number": 23792407,
  //       "file_name": "invoice5",
  //       "sp_name": "TSSC",
  //       "sp_state": "Haryaana",
  //       "sp_emailId": "tssc@gmail.com",
  //       "sp_PhoneNumber": "8210575712",
  //       "supplyCode": 23,
  //       "GSTIN": null,
  //       "invoiceNo": "TSS/1",
  //       "Date": "1/2/23",
  //       "c_CompanyName": "Flaviant Network Pvt. Ltd.",
  //       "c_Address": "1st Floor, IAPL House, 19 Pusa Road Karol Bagh, New Delhi-110005 ",
  //       "c_GSTIN": "07AADCF4830DIZO",
  //       "c_email": "asst.accountant@flaviant.com",
  //       "sp_panNumber": "63763773gdgd",
  //       "sp_AccocuntHolderName": "rakedsh",
  //       "sp_accountNumber": "6327127828",
  //       "sp_IFSC_Code": "dd122",
  //       "sp_bankName": "HDFC",
  //       "sp_branchName": "gurugram",
  //       "subTotal": "20",
  //       "SGST": null,
  //       "CGST": null,
  //       "IGST": null,
  //       "Total": "20",
  //       "TotalNumber": "twenty",
  //       "createdAt": "2023-02-03T06:51:56.000Z",
  //       "updatedAt": "2023-02-03T06:51:56.000Z",
  //       "Service_lists": [
  //           {
  //               "id": 1,
  //               "detailinvoicesId": 1,
  //               "Description": "Book",
  //               "HSN_SAC": 4,
  //               "qty": 5,
  //               "unit_price": 100,
  //               "Ammount": 500
  //           },
  //           {
  //               "id": 2,
  //               "detailinvoicesId": 1,
  //               "Description": "Book",
  //               "HSN_SAC": 4,
  //               "qty": 5,
  //               "unit_price": 100,
  //               "Ammount": 500
  //           },
  //           {
  //               "id": 3,
  //               "detailinvoicesId": 1,
  //               "Description": "Bag",
  //               "HSN_SAC": 5,
  //               "qty": 1,
  //               "unit_price": 500,
  //               "Ammount": 500
  //           },
  //           {
  //               "id": 4,
  //               "detailinvoicesId": 1,
  //               "Description": "lunch",
  //               "HSN_SAC": 5,
  //               "qty": 1,
  //               "unit_price": 500,
  //               "Ammount": 500
  //           }
  //       ]
  //   },
  //   {
  //       "id": 2,
  //       "file_number": 23792407,
  //       "file_name": "invoice5",
  //       "sp_name": "Forum Ias ",
  //       "sp_state": "Jharkhand",
  //       "sp_emailId": "tssc@gmail.com",
  //       "sp_PhoneNumber": "8210575712",
  //       "supplyCode": 19,
  //       "GSTIN": null,
  //       "invoiceNo": "For/1",
  //       "Date": "1/1/22",
  //       "c_CompanyName": "Flaviant Network Pvt. Ltd.",
  //       "c_Address": "1st Floor, IAPL House, 19 Pusa Road Karol Bagh, New Delhi-110005 ",
  //       "c_GSTIN": "07AADCF4830DIZO",
  //       "c_email": "asst.accountant@flaviant.com",
  //       "sp_panNumber": "2343134",
  //       "sp_AccocuntHolderName": "assasascc",
  //       "sp_accountNumber": "13456542342",
  //       "sp_IFSC_Code": "q123as",
  //       "sp_bankName": "ddfvbgbbds",
  //       "sp_branchName": "adfvfvfggg",
  //       "subTotal": "6",
  //       "SGST": null,
  //       "CGST": null,
  //       "IGST": null,
  //       "Total": "6",
  //       "TotalNumber": "six",
  //       "createdAt": "2023-02-03T06:51:56.000Z",
  //       "updatedAt": "2023-02-03T06:51:56.000Z",
  //       "Service_lists": [
  //           {
  //               "id": 5,
  //               "detailinvoicesId": 2,
  //               "Description": "gsahxsbjhnxsn",
  //               "HSN_SAC": 3,
  //               "qty": 2,
  //               "unit_price": 100000000,
  //               "Ammount": 2000
  //           }
  //       ]
  //   },
  //   {
  //       "id": 3,
  //       "file_number": 23792407,
  //       "file_name": "invoice5",
  //       "sp_name": "gurgram",
  //       "sp_state": "Uttar pradesh",
  //       "sp_emailId": "hdjnjs@gmail.com",
  //       "sp_PhoneNumber": "9292837373",
  //       "supplyCode": 19,
  //       "GSTIN": null,
  //       "invoiceNo": "gur/1",
  //       "Date": "1/1/22",
  //       "c_CompanyName": "Flaviant Network Pvt. Ltd.",
  //       "c_Address": "1st Floor, IAPL House, 19 Pusa Road Karol Bagh, New Delhi-110005 ",
  //       "c_GSTIN": "07AADCF4830DIZO",
  //       "c_email": "asst.accountant@flaviant.com",
  //       "sp_panNumber": "632719`9`37",
  //       "sp_AccocuntHolderName": "assasascc",
  //       "sp_accountNumber": "13456542342",
  //       "sp_IFSC_Code": "q123as",
  //       "sp_bankName": "ddfvbgbbds",
  //       "sp_branchName": "adfvfvfggg",
  //       "subTotal": "6",
  //       "SGST": null,
  //       "CGST": null,
  //       "IGST": null,
  //       "Total": "6",
  //       "TotalNumber": "six",
  //       "createdAt": "2023-02-03T06:51:56.000Z",
  //       "updatedAt": "2023-02-03T06:51:56.000Z",
  //       "Service_lists": [
  //           {
  //               "id": 6,
  //               "detailinvoicesId": 3,
  //               "Description": "gsahxsbjhnxsn",
  //               "HSN_SAC": 3,
  //               "qty": 2,
  //               "unit_price": 2389,
  //               "Ammount": 4778
  //           }
  //       ]
  //   },
  //   {
  //       "id": 4,
  //       "file_number": 23792407,
  //       "file_name": "invoice5",
  //       "sp_name": "ABC",
  //       "sp_state": "Delhi",
  //       "sp_emailId": "hdjnjs@gmail.com",
  //       "sp_PhoneNumber": "9292837373",
  //       "supplyCode": 19,
  //       "GSTIN": null,
  //       "invoiceNo": "ABC/1",
  //       "Date": "1/1/22",
  //       "c_CompanyName": "Flaviant Network Pvt. Ltd.",
  //       "c_Address": "1st Floor, IAPL House, 19 Pusa Road Karol Bagh, New Delhi-110005 ",
  //       "c_GSTIN": "07AADCF4830DIZO",
  //       "c_email": "asst.accountant@flaviant.com",
  //       "sp_panNumber": "632719`9`37",
  //       "sp_AccocuntHolderName": "assasascc",
  //       "sp_accountNumber": "13456542342",
  //       "sp_IFSC_Code": "q123as",
  //       "sp_bankName": "ddfvbgbbds",
  //       "sp_branchName": "adfvfvfggg",
  //       "subTotal": "6",
  //       "SGST": null,
  //       "CGST": null,
  //       "IGST": null,
  //       "Total": "6",
  //       "TotalNumber": "six",
  //       "createdAt": "2023-02-03T06:51:57.000Z",
  //       "updatedAt": "2023-02-03T06:51:57.000Z",
  //       "Service_lists": [
  //           {
  //               "id": 7,
  //               "detailinvoicesId": 4,
  //               "Description": "gsahxsbjhnxsn",
  //               "HSN_SAC": 3,
  //               "qty": 2,
  //               "unit_price": 9870,
  //               "Ammount": 19740
  //           }
  //       ]
  //   },
  //   {
  //       "id": 5,
  //       "file_number": 23792407,
  //       "file_name": "invoice5",
  //       "sp_name": "Forum Ias ",
  //       "sp_state": "Delhi",
  //       "sp_emailId": "hdjnjs@gmail.com",
  //       "sp_PhoneNumber": "9292837373",
  //       "supplyCode": 19,
  //       "GSTIN": null,
  //       "invoiceNo": "For/2",
  //       "Date": "1/1/22",
  //       "c_CompanyName": "Flaviant Network Pvt. Ltd.",
  //       "c_Address": "1st Floor, IAPL House, 19 Pusa Road Karol Bagh, New Delhi-110005 ",
  //       "c_GSTIN": "07AADCF4830DIZO",
  //       "c_email": "asst.accountant@flaviant.com",
  //       "sp_panNumber": "632719`9`37",
  //       "sp_AccocuntHolderName": "assasascc",
  //       "sp_accountNumber": "13456542342",
  //       "sp_IFSC_Code": "q123as",
  //       "sp_bankName": "ddfvbgbbds",
  //       "sp_branchName": "adfvfvfggg",
  //       "subTotal": "6",
  //       "SGST": null,
  //       "CGST": null,
  //       "IGST": null,
  //       "Total": "6",
  //       "TotalNumber": "six",
  //       "createdAt": "2023-02-03T06:51:57.000Z",
  //       "updatedAt": "2023-02-03T06:51:57.000Z",
  //       "Service_lists": [
  //           {
  //               "id": 8,
  //               "detailinvoicesId": 5,
  //               "Description": "gsahxsbjhnxsn",
  //               "HSN_SAC": 3,
  //               "qty": 2,
  //               "unit_price": 9870,
  //               "Ammount": 19740
  //           },
  //           {
  //               "id": 9,
  //               "detailinvoicesId": 5,
  //               "Description": "gsahxsbjhnxsn",
  //               "HSN_SAC": 3,
  //               "qty": 2,
  //               "unit_price": 9870,
  //               "Ammount": 19740
  //           },
  //           {
  //               "id": 10,
  //               "detailinvoicesId": 5,
  //               "Description": "gsahxsbjhnxsn",
  //               "HSN_SAC": 3,
  //               "qty": 2,
  //               "unit_price": 9870,
  //               "Ammount": 19740
  //           }
  //       ]
  //   },
  //   {
  //       "id": 6,
  //       "file_number": 23792407,
  //       "file_name": "invoice5",
  //       "sp_name": "gurgram",
  //       "sp_state": "Uttar pradesh",
  //       "sp_emailId": "hdjnjs@gmail.com",
  //       "sp_PhoneNumber": "9292837373",
  //       "supplyCode": 19,
  //       "GSTIN": null,
  //       "invoiceNo": "gur/2",
  //       "Date": "1/1/22",
  //       "c_CompanyName": "Flaviant Network Pvt. Ltd.",
  //       "c_Address": "1st Floor, IAPL House, 19 Pusa Road Karol Bagh, New Delhi-110005 ",
  //       "c_GSTIN": "07AADCF4830DIZO",
  //       "c_email": "asst.accountant@flaviant.com",
  //       "sp_panNumber": "632719`9`37",
  //       "sp_AccocuntHolderName": "assasascc",
  //       "sp_accountNumber": "13456542342",
  //       "sp_IFSC_Code": "q123as",
  //       "sp_bankName": "ddfvbgbbds",
  //       "sp_branchName": "adfvfvfggg",
  //       "subTotal": "6",
  //       "SGST": null,
  //       "CGST": null,
  //       "IGST": null,
  //       "Total": "6",
  //       "TotalNumber": "six",
  //       "createdAt": "2023-02-03T06:51:57.000Z",
  //       "updatedAt": "2023-02-03T06:51:57.000Z",
  //       "Service_lists": [
  //           {
  //               "id": 11,
  //               "detailinvoicesId": 6,
  //               "Description": "gsahxsbjhnxsn",
  //               "HSN_SAC": 3,
  //               "qty": 2,
  //               "unit_price": 2389,
  //               "Ammount": 4778
  //           }
  //       ]
  //   }
  // ]
  let docs = [];
  let j = 0;

  for (let i = 0; i < plants.length; i++) {
    j++;
    docs.push([fileName + j, <SampleDocument data={plants[i]} />]);
  }

  console.log(docs);

  console.log(docs);
  // const docs=<SampleDocument/>
  const download = () => {
    Promise.all(
      docs.map(([name, doc]) =>
        pdf(doc)
          .toBlob()
          .then((b) => [name, b])
      )
    ).then((blobs) => {
      console.log(blobs);

      var zip = new JSZip();

      for (let [name, blob] of blobs) {
        console.log("File compression");
        console.log(name);
        zip.file(name + ".pdf", blob, { blob: true });
      }
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "invoice.zip");
      });
    });
  };

  const setFileNameForTheInputForm = (name) => {
    console.log(name);
    setFileName(name);
  };

  console.log(fileName);
  console.log(plants);
  return (
    <>
      <div className="container py-5">
        <h1 style={{ color: "red" }} className="mb-4">
          Flaviant Invoice PDF Generator{" "}
        </h1>
        <div className="btn-group mb-3" role="group" aria-label="Basic example">
          <button
            className="btn btn-outline-warning btn-lg"
            onClick={() =>
              setFormShow({
                ...formShow,
                excel: !formShow.excel,
                individual: false,
              })
            }
          >
            Enter Details
          </button>

          <button
            className="btn btn-outline-warning btn-lg"
            onClick={() =>
              setFormShow({
                ...formShow,
                individual: !formShow.individual,
                excel: false,
              })
            }
          >
            Upload Excel
          </button>

          <button
            className="btn btn-outline-warning btn-lg"
            onClick={() => setShow(true)}
          >
            Archive Files
          </button>
        </div>
        {formShow.excel ? (
          <ExcelForm
            functionCall={getTheFileData}
            setName={setFileNameForTheInputForm}
          />
        ) : (
          ""
        )}
        {formShow.individual ? (
          <NewFile
            functionCall={getTheFileData}
            setName={setFileNameForTheInputForm}
          />
        ) : (
          ""
        )}
        <hr />
        <button
          onClick={download}
          className="btn btn-outline-danger mb-3"
          disabled={plants.length === 0}
        >
          Download Files as zip
        </button>
        <br />
        Click{" "}
        <a
          //href="https://stellarlabs-my.sharepoint.com/:x:/g/personal/sumit_j_stellardigital_in/EZoiguw6lJdEu0L3YqCdf2ABEm-wG0KnV0YY0eaOr6kdoA?rtime=ortjGJsK20g"
          href="https://s3.amazonaws.com/node.invoice.bucket/InvoiceGenerator/file-final.xlsx"
          className="link-info"
          target="_blank"
          rel="noreferrer"
        >
          Here
        </a>{" "}
        to download the sample EXCEL file
        {/* *To view the PDF open it on the edge or Mozilla browser */}
        {isFetching ? (
          <>
            <div className="text-center mt-4">
              <img src={loadingGif} alt="" className="img-fluid" />
            </div>
          </>
        ) : (
          docs.map((doc) => <PDFViewer>{doc}</PDFViewer>)
        )}
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        // aria-labelledby="example-modal-sizes-title-lg"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <DownloadedFile
            functionCall={getTheFileData}
            close={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
